import styled from '@emotion/styled';
import {FINETUNED, PerformerSummary, TeamName} from '../../definitions/metrics';
import {ColorCircle, HeaderText} from '../Legend/Shared';
import {useDataContext} from '../../context/DataContext';

export interface SubmissionsProps {
  teamName?: TeamName | null;
  performerSummary: PerformerSummary[];
}

export interface SubmissionItemProps {
  data: PerformerSummary;
}

export const Submissions: React.FC<SubmissionsProps> = ({teamName, performerSummary}) => {
  let data: any[] = [];
  if (performerSummary) {
    data = teamName ? [performerSummary.find((d) => d.Team === teamName)] : performerSummary;
  }
  return (
    <SubmissionsCard>
      <HeaderText>Submissions</HeaderText>
      {teamName ? (
        <Grid>
          {data &&
            data.map(
              (submissionSummary) =>
                submissionSummary && <SubmissionItem data={submissionSummary} key={submissionSummary.PerformerName} />
            )}
        </Grid>
      ) : (
        <Grid>
          {data.map(
            (submissionSummary) =>
              submissionSummary && <SubmissionItem data={submissionSummary} key={submissionSummary.PerformerName} />
          )}
        </Grid>
      )}
    </SubmissionsCard>
  );
};

export const SubmissionItem: React.FC<SubmissionItemProps> = ({data}) => {
  const [, {getSystemColor, getPatternColor}] = useDataContext();
  if (data.PerformerName === 'Baseline') return <></>;
  return (
    <Column>
      <PerformerNameContainer>{data.Team}</PerformerNameContainer>
      <Row>
        {data.SystemList.map((systemName: string) => (
          <ColorCircle
            color={getSystemColor(data.PerformerName, systemName)}
            patternColor={getPatternColor(data.PerformerName, systemName)}
            size={'18px'}
            key={`Submission-${data.PerformerName}-${systemName}`}
            isFineTuned={systemName.includes(FINETUNED)}
            spaceOut
          />
        ))}
      </Row>
    </Column>
  );
};

const SubmissionsCard = styled.div`
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  padding: 1rem;
  width: 40%;
  min-width: 345px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 1.5rem;
  row-gap: 1.5rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const PerformerNameContainer = styled.div`
  font-size: 10px;
  margin-bottom: 3px;
`;
