/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {Header} from '../components/Header';
import {Content, Page} from '../styles/common';

export const BadVersion: React.FC<{}> = () => {
  return (
    <Page>
      <Header />
      <Content>
        <Para>
          Data version in url is not recognized. Please select a new data version from the dropdown at the top right of
          the page.
        </Para>
      </Content>
    </Page>
  );
};

export const Para = styled.p`
  padding: 1.75rem;
`;
