import styled from '@emotion/styled';
import {PerformerName, TeamName} from '../../definitions/metrics';
import {performerTheme} from '../../styles/performerTheme';
import CRA from '../../assets/CharlesRiver.png';
import Leidos from '../../assets/Leidos.png';
import Raytheon from '../../assets/Raytheon.png';
import SRI from '../../assets/SRI.png';
import UPenn from '../../assets/UPenn.png';
import USC from '../../assets/USC.png';
import React from 'react';
import {ColorCircle, HeaderText, LegendCard, Row, SubHeader} from './Shared';
import {useDataContext} from '../../context/DataContext';
import {SystemLegendItem} from './SystemLegend';

export const PERFORMER_ICONS: Record<string, string> = {
  [PerformerName.Author]: CRA,
  [PerformerName.Relax]: Leidos,
  [PerformerName.Alert]: Raytheon,
  [PerformerName.Signature]: SRI,
  [PerformerName.Pausit]: UPenn,
  [PerformerName.Sadiri]: USC,
};

export interface PerformerLegendProps {
  teamNames: Record<string, string>;
}

export interface PerformerLegendItemProps {
  performerName: string;
}

export const PerformerLegend: React.FC<PerformerLegendProps> = ({teamNames}) => {
  const [{teamSystems}] = useDataContext();
  const baselineSystemList = teamSystems[TeamName.Baseline];
  return (
    <LegendCard>
      <Row>
        <HeaderText>Legend</HeaderText>
        <SubHeader>(Primes)</SubHeader>
      </Row>
      <Grid>
        {baselineSystemList?.map((systemName: string) => (
          <React.Fragment key={systemName}>
            <SystemLegendItem performerName={PerformerName.Baseline} systemName={systemName} />
          </React.Fragment>
        ))}
        {teamNames &&
          Object.keys(teamNames).map((performerName: string) => (
            <React.Fragment key={performerName}>
              {performerName !== PerformerName.Baseline && <PerformerLegendItem performerName={performerName} />}
            </React.Fragment>
          ))}
      </Grid>
    </LegendCard>
  );
};

export const PerformerLegendItem: React.FC<PerformerLegendItemProps> = ({performerName}) => {
  //const [{performerFriendlyNames}] = useDataContext();
  const color = performerTheme[performerName as PerformerName].primary;
  //const friendlyName = performerFriendlyNames[performerName];
  return (
    <Row>
      <ColorCircle color={color} size={'18px'} />
      <PerformerLabel color={color}>
        <img
          src={PERFORMER_ICONS[performerName]}
          alt={`${performerName} Logo`}
          width="20px"
          height="20px"
          style={{marginRight: '2px'}}
        />
        {/* {`${friendlyName} (${performerName})`} */}
        {performerName}
      </PerformerLabel>
    </Row>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(3, 24px);
  grid-auto-flow: column;
  margin-top: 1.5rem;
  row-gap: 0.5rem;
  column-gap: 1rem;
`;

const PerformerLabel = styled.div`
  border: 2px solid ${(props: any) => props.color};
  border-radius: 1rem;
  padding: 0px 6px 0px 2px;
  margin-left: 0.5rem;
  font-size: 10px;
`;
