import {PerformerName} from '../definitions/metrics';

export const performerTheme = {
  [PerformerName.Alert]: {
    primary: '#A39F35',
    shades: ['#5C5910', '#A39F35', '#D5D3A2'],
    patterns: ['#a39f35', '#d3d078', '#f9f8ec'],
    text: '#807C23',
  },
  [PerformerName.Author]: {
    primary: '#7B5A7B',
    shades: ['#502050', '#7B5A7B', '#B9A5B9'],
    patterns: ['#936c93', '#bea7be', '#f4f0f4'],
    text: '#6A416A',
  },
  [PerformerName.Pausit]: {
    primary: '#609A85',
    shades: ['#2D5849', '#609A85', '#A8D5C5'],
    patterns: ['#72a793', '#b1cec3', '#eff5f3'],
    text: '#4C7E6C',
  },
  [PerformerName.Relax]: {
    primary: '#956A54',
    shades: ['#53311F', '#956A54', '#C3ADA1'],
    patterns: ['#bf9d8d', '#dac7be', '#f6f1ef'],
    text: '#6A432F',
  },
  [PerformerName.Sadiri]: {
    primary: '#5385B1',
    shades: ['#29557C', '#5385B1', '#BACEE0'],
    patterns: ['#729cc0', '#a7c1d7', '#edf3f7'],
    text: '#3E6D97',
  },
  [PerformerName.Signature]: {
    primary: '#AF7780',
    shades: ['#6C343D', '#AF7780', '#E8C1C7'],
    patterns: ['#bd8f96', '#d9bfc3', '#f6eff0'],
    text: '#8E565F',
  },
  [PerformerName.Baseline]: {
    primary: '#5A5056',
    shades: ['#45383F', '#776E73', '#BCB5B9'],
    patterns: ['#776E73', '#BCB5B9', '#776E73'],
    text: '#5A5056',
  },
};
