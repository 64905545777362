export const theme = {
  colors: {
    textPrimary: '#45383F',
    neutral100: '#45383F',
    neutral80: '#5A5056',
    neutral60: '#776E73',
    neutral50: '#aaa1a6',
    neutral40: '#BCB5B9',
    neutral20: '#E0DDDF',
    neutral0: '#FFFFFF',
    neutral0Shadow: 'rgba(224, 221, 223, 0.6)',
    chartreuse: '#A39F35',
    plum: '#846284',
    mint: '#6BA38F',
    cinnamon: '#80553F',
    sky: '#5385B1',
    blush: '#AF7780',
  },
};
