import {UserOutlined} from '@ant-design/icons';
import styled from '@emotion/styled';
import {Button, Dropdown, MenuProps} from 'antd';
import {NavLink, useLocation, useMatch, useNavigate} from 'react-router-dom';
import {useAuthContext} from '../context/AuthContext';
import {UserRole} from '../definitions/auth';
import {TeamName} from '../definitions/metrics';
import {PageRoutes} from '../definitions/routes';
import {DefaultNavigation} from './DefaultNavigation';
import {DropdownMenu, getDropdownItemsFromArray} from './DropdownMenu';
import {useDataContext} from '../context/DataContext';
import {useEffect, useMemo} from 'react';
import {CameraOutlined} from '@ant-design/icons';
import {Tooltip} from 'antd';
import React from 'react';

const PerformerDropdown: React.FC = () => {
  const [{selectedTeam}, {setSelectedTeam}] = useAuthContext();
  const [{teamsList}] = useDataContext();

  useEffect(() => {
    if (!selectedTeam && teamsList) {
      setSelectedTeam(teamsList[0]);
    }
  }, [teamsList, selectedTeam, setSelectedTeam]);

  if (teamsList) {
    const performers = getDropdownItemsFromArray(teamsList, 'PerformerTeam');
    const selectedItem = performers.find((performer) => performer.label === selectedTeam);

    return (
      <DropdownMenu
        className={'performer-dropdown'}
        selectedItem={selectedItem}
        setSelectedItemInParent={(item) => item.label && setSelectedTeam(item.label as TeamName)}
        items={performers}
      />
    );
  } else {
    return <div />;
  }
};

const GenreDropdown: React.FC = () => {
  const [{performerSummary, selectedGenre}, {setSelectedGenre}] = useDataContext();

  useEffect(() => {
    if (!selectedGenre && performerSummary && performerSummary.length > 0) {
      setSelectedGenre(performerSummary[0].GenreList[0]);
    }
  }, [performerSummary, selectedGenre, setSelectedGenre]);

  if (performerSummary && performerSummary.length > 0) {
    const genres = performerSummary[0].GenreList;
    const genreItems = getDropdownItemsFromArray(genres, 'Genre');
    const selectedItem = genreItems.find((genre) => genre.label === selectedGenre);

    return (
      <DropdownMenu
        className={'performer-dropdown'}
        selectedItem={selectedItem}
        setSelectedItemInParent={(item) => item.label && setSelectedGenre(item.label)}
        items={genreItems}
        css={{marginRight: '1.25rem'}}
      />
    );
  } else {
    return <div />;
  }
};

const VersionsDropdown: React.FC<{onNewVersionSelect: (version: string) => void}> = ({onNewVersionSelect}) => {
  const [{currentVersion, dataVersions}] = useDataContext();

  if (dataVersions && dataVersions.AllVersions.length >= 1) {
    const versions = getDropdownItemsFromArray(dataVersions.AllVersions, 'DataVersion');
    if (dataVersions.AllVersions.indexOf(currentVersion) === -1) {
      versions.unshift({label: '--', key: 'DataVersion-null'});
    }
    const selectedItem = versions.find((version) => version.label === currentVersion);

    return (
      <DropdownMenu
        className={'versions-dropdown'}
        selectedItem={selectedItem}
        setSelectedItemInParent={(item) => item.label && onNewVersionSelect(item.label)}
        items={versions}
        css={{marginRight: '0.5rem'}}
        disabled={dataVersions.AllVersions.length === 1}
      />
    );
  } else {
    return <div />;
  }
};

export interface HeaderProps {
  onScreenshotClick?: () => void;
}
export const Header: React.FC<HeaderProps> = ({onScreenshotClick}) => {
  const [{user, userGroup, loginURI}] = useAuthContext();
  const [{currentVersion}, {setCurrentVersion, invalidateData}] = useDataContext();
  const match = useMatch({path: '/'});
  const location = useLocation();
  const navigate = useNavigate();

  const adminHeaderItems = useMemo(
    () => [
      {
        route: `/${currentVersion}/${PageRoutes.PERFORMERCOMPARISONPARTIAL}`,
        text: 'Performer Comparison',
        element: <GenreDropdown />,
      },
      {
        route: `/${currentVersion}/${PageRoutes.PERFORMEROVERVIEWPARTIAL}`,
        text: 'Performer Overview',
        element: <PerformerDropdown />,
      },
    ],
    [currentVersion]
  );

  const defaultHeaderItems = useMemo(
    () => [
      {
        route: `${currentVersion}/${PageRoutes.PERFORMEROVERVIEWPARTIAL}`,
        text: 'Performer Overview',
      },
    ],
    [currentVersion]
  );

  if (match !== null) {
    return <DefaultNavigation userGroup={userGroup} />;
  }

  const onSignOut = () => {
    if (user) {
      user.signOut();
      if (loginURI) {
        window.location.href = loginURI;
      }
    }
  };
  const userActions: MenuProps['items'] = [
    {
      key: 'logout',
      label: (
        <div role="button" onClick={onSignOut}>
          Logout
        </div>
      ),
    },
  ];

  const onNewVersionSelect = (newVersion: string) => {
    const urlParts = location.pathname.split('/');
    const newUrl = `/${newVersion}/${urlParts[urlParts.length - 1]}`;
    setCurrentVersion('');
    invalidateData();
    navigate(newUrl);
  };

  return (
    <HeaderContainer>
      {userGroup?.role === UserRole.Admin ? (
        <LeftItems>
          {adminHeaderItems.map(({route, text, element}) => (
            <HeaderTab to={route} key={route}>
              <HeaderTextContainer>
                <HeaderText>{text}</HeaderText>
                {element && element}
              </HeaderTextContainer>
            </HeaderTab>
          ))}
        </LeftItems>
      ) : (
        <LeftItems>
          {defaultHeaderItems.map(({route, text}) => (
            <HeaderTab to={route} key={route}>
              <HeaderTextContainer>
                <HeaderText>{text}</HeaderText>
              </HeaderTextContainer>
            </HeaderTab>
          ))}
        </LeftItems>
      )}
      <div>
        <VersionsDropdown onNewVersionSelect={onNewVersionSelect} />
        {onScreenshotClick && (
          <div style={{float: 'right', paddingLeft: '0.3rem'}}>
            <Tooltip title="Download as png">
              <Button shape="default" icon={<CameraOutlined />} style={{float: 'right'}} onClick={onScreenshotClick} />
            </Tooltip>
          </div>
        )}
        {user && (
          <Dropdown menu={{items: userActions}} placement="bottom" arrow={{pointAtCenter: true}}>
            <Button icon={<UserOutlined />}>{user?.getUsername()}</Button>
          </Dropdown>
        )}
      </div>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  height: 4.5rem;
  width: 100%;
  display: flex;
  background: ${(props: any) => props.theme.colors.neutral40};
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem;
`;

const LeftItems = styled.div`
  display: flex;
  align-items: center;
`;

const HeaderTab = styled(NavLink)<any>`
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  .performer-dropdown {
    display: none;
  }
  &:hover {
    div {
      background: ${(props: any) => props.theme.colors.neutral50};
      border-radius: 1px;
    }
  }
  &.active {
    div {
      background: ${(props: any) => props.theme.colors.neutral60};
      color: ${(props: any) => props.theme.colors.neutral0};
      border-radius: 1px;
    }
    .performer-dropdown {
      display: block;
    }
  }
`;

const HeaderTextContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0 0.25rem;
  color: ${(props: any) => props.theme.colors.neutral80};
`;

const HeaderText = styled.span`
  width: fit-content;
  padding: 0.3rem 0.6rem;
  margin-right: 1.25rem;
  font-family: 'Press Start 2P';
  font-size: 14px;
  white-space: nowrap;
`;
