/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {Header} from '../components/Header';
import {Content, Page} from '../styles/common';

export const Guest: React.FC<{}> = () => {
  return (
    <Page>
      <Header />
      <Content>
        <Para>
          Welcome! You are not registered to any performer or admin groups at this time. Please contact the Hiatus team
          to join a group.
        </Para>
      </Content>
    </Page>
  );
};

export const Para = styled.p`
  padding: 1.75rem;
`;
