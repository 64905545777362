import styled from '@emotion/styled';

export const Page = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  height: calc(100vh - 4rem);
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1.5rem 1.5rem 1.5rem 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Center = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
