/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {FINETUNED, GridMetric, GridProperty, MetricsSummary, PerformerName} from '../../definitions/metrics';
import {nest} from 'd3-collection';
import {performerTheme} from '../../styles/performerTheme';
import React, {useState, useRef} from 'react';
import {Button, Popover, Tooltip} from 'antd';
import {SettingOutlined} from '@ant-design/icons';
import {MetricsSettingsModal} from './MetricsSettingsModal';
import {useDataContext} from '../../context/DataContext';
import {ErrorBoundary} from '../ErrorBoundary';
import {CameraOutlined} from '@ant-design/icons';
import {exportComponentAsPNG} from 'react-component-export-image';
import {HeaderText} from '../Legend/Shared';

export interface MetricsGridProps {
  data: GridMetric[];
  columnLabels?: Record<string, string>;
  columnProperty?: GridProperty; // Default is PerformerName
}

export const MetricsGrid: React.FC<MetricsGridProps> = ({
  data,
  columnLabels,
  columnProperty = 'PerformerName',
}: MetricsGridProps) => {
  const [
    {metricsSummary, primaryMetrics, defaultDisplayMetrics, performerTeams, teamSystems},
    {getSystemColor, getPatternColor, getReverseMetric},
  ] = useDataContext();
  const groupedData = getGroupedData(data);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [visibleMetrics, setVisibleMetrics] = useState<Record<string, boolean>>(
    getVisibleMetrics(metricsSummary, defaultDisplayMetrics)
  );

  const numColumns = groupedData[0].values[0].values.length;

  const getUseDarkText = (gridMetric: GridMetric) => {
    const theme = performerTheme[gridMetric.PerformerName as PerformerName];
    const team = performerTeams[gridMetric.PerformerName];
    const systemList = teamSystems[team];
    if (theme) {
      const systemNum = systemList.indexOf(gridMetric.System.replace(FINETUNED, ''));
      return systemNum === 2;
    }
  };

  const getTextColor = (gridMetric: GridMetric) => {
    const theme = performerTheme[gridMetric.PerformerName as PerformerName];
    if (theme) {
      return theme.text;
    }
  };

  const popoverContent = (gridMetric: GridMetric) => (
    <PopoverContent>
      <span>{gridMetric.Metric}</span>
      <span>Baseline value: {gridMetric.Baseline_Value?.toFixed(4)}</span>
      <span>
        {`${
          columnLabels && gridMetric[columnProperty]
            ? columnLabels[gridMetric[columnProperty]!]
            : gridMetric[columnProperty]
        } value: ${gridMetric.Value?.toFixed(4)}`}
      </span>
    </PopoverContent>
  );

  const getTaskAreaGrid = (group: string) => {
    const index = groupedData.findIndex((data) => data.key === group);
    groupedData[index].values.sort((a: {key: string; values: any}, b: {key: string; values: any}) => {
      if (primaryMetrics.includes(`${groupedData[index].key}|${a.key}`)) return -1;
      else if (primaryMetrics.includes(`${groupedData[index].key}|${b.key}`)) return 1;
      return 0;
    });
    return (
      <Grid numColumns={numColumns}>
        {groupedData[index].values.map((metricData: {key: string; values: any}) => {
          if (visibleMetrics[`${groupedData[index].key}|${metricData.key}`]) {
            return (
              <React.Fragment key={`${metricData.key}-row`}>
                <MetricName key={`${metricData.key}-label`}>
                  <div>{metricData.key}</div>
                </MetricName>
                {metricData.values.map((gridMetric: GridMetric) => (
                  <Popover
                    content={popoverContent(gridMetric)}
                    placement="bottom"
                    key={`${gridMetric.Metric}-${gridMetric[columnProperty]}-popover`}
                  >
                    <MetricValue
                      color={getSystemColor(gridMetric.PerformerName, gridMetric.System)}
                      textColor={getTextColor(gridMetric)}
                      patternColor={getPatternColor(
                        gridMetric.PerformerName,
                        gridMetric.System,
                        !!(gridMetric.Improvement && gridMetric.Improvement < 0)
                      )}
                      isFineTuned={gridMetric.System.includes(FINETUNED)}
                      key={`${gridMetric.Metric}-${gridMetric[columnProperty]}`}
                      inverted={
                        gridMetric.Improvement &&
                        gridMetric.Improvement * getReverseMetric(gridMetric.Metric, gridMetric.Group) < 0
                      }
                      darkText={getUseDarkText(gridMetric)}
                    >
                      <CenterDiv>{gridMetric.System}</CenterDiv>
                      <PercentImprovement>
                        {gridMetric.Improvement !== null &&
                          `${
                            Math.round(gridMetric.Improvement * 100) *
                            getReverseMetric(gridMetric.Metric, gridMetric.Group)
                          }%`}
                      </PercentImprovement>
                    </MetricValue>
                  </Popover>
                ))}
              </React.Fragment>
            );
          }
          return <React.Fragment key={`${metricData.key}-skip`}></React.Fragment>;
        })}
      </Grid>
    );
  };
  const downloadMetricsGrid = useRef(null as any);
  return (
    <MetricsContainer>
      <div style={{float: 'right', padding: '0.2rem'}}>
        <Tooltip title="Download as png">
          <Button
            shape="default"
            icon={<CameraOutlined />}
            onClick={() => {
              exportComponentAsPNG(downloadMetricsGrid, {
                fileName: 'metrics_grid.png',
                html2CanvasOptions: {
                  height: downloadMetricsGrid.current.scrollHeight,
                  width: downloadMetricsGrid.current.scrollWidth + 0.01 * downloadMetricsGrid.current.scrollWidth,
                },
              });
            }}
          />
        </Tooltip>
      </div>
      <Header>
        <HeaderText>Metrics</HeaderText>
        <ErrorBoundary invisible>
          <SettingsButton
            shape="circle"
            type="ghost"
            icon={<StyledSettingsIcon />}
            onClick={() => setShowModal(true)}
          />
          <MetricsSettingsModal
            showModal={showModal}
            setShowModal={setShowModal}
            visibleMetrics={visibleMetrics}
            setVisibleMetrics={setVisibleMetrics}
          />
        </ErrorBoundary>
      </Header>

      <Table ref={downloadMetricsGrid} style={{backgroundColor: 'white'}}>
        <thead>
          <tr>
            <th></th>
            <th>
              <HeaderGrid numColumns={numColumns}>
                {groupedData[0].values[0].values.map((d: GridMetric) => (
                  <ColumnHeader key={d[columnProperty]}>
                    {columnLabels && d[columnProperty] ? columnLabels[d[columnProperty]!] : d[columnProperty]}
                  </ColumnHeader>
                ))}
              </HeaderGrid>
            </th>
          </tr>
        </thead>
        <tbody>
          {groupedData.findIndex((data) => data.key === 'TA1') !== -1 && (
            <tr>
              <TaskAreaName>
                <TaskAreaText>Feature Space Generation</TaskAreaText>
              </TaskAreaName>
              <td>{getTaskAreaGrid('TA1')}</td>
            </tr>
          )}
          {groupedData.findIndex((data) => data.key === 'TA2') !== -1 && (
            <tr>
              <TaskAreaName>
                <TaskAreaText>Text Attribution</TaskAreaText>
              </TaskAreaName>
              <td>{getTaskAreaGrid('TA2')}</td>
            </tr>
          )}
          {groupedData.findIndex((data) => data.key === 'TA3') !== -1 && (
            <tr>
              <TaskAreaName>
                <TaskAreaText>Privacy Preservation</TaskAreaText>
              </TaskAreaName>
              <td>{getTaskAreaGrid('TA3')}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </MetricsContainer>
  );
};

export const getVisibleMetrics = (metricsSummary: MetricsSummary[] | null, defaultDisplayMetrics: string[]) => {
  const visibleMetrics: Record<string, boolean> = {};
  if (metricsSummary) {
    for (let metricsGroup of metricsSummary) {
      for (let metric of metricsGroup.MetricsList) {
        visibleMetrics[`${metricsGroup.Group}|${metric}`] = defaultDisplayMetrics.includes(
          `${metricsGroup.Group}|${metric}`
        );
      }
    }
  }
  return visibleMetrics;
};

export const getGroupedData = (data: GridMetric[]) => {
  return nest()
    .key(function (d) {
      return (d as GridMetric).Group;
    })
    .key(function (d) {
      return (d as GridMetric).Metric;
    })
    .entries(data);
};

const MetricsContainer = styled.div`
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  padding: 0.5rem 0.5rem 3rem 0.5rem;
  margin-bottom: 1.5rem;
  flex: 1;
`;

const Table = styled.table`
  border-spacing: 8px;
  border-collapse: separate;
  height: 100%;
  width: 100%;
  font-size: 14px;
`;

interface GridProps {
  numColumns: number;
}

const HeaderGrid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${(props: GridProps) => `repeat(${props.numColumns}, 1fr)`};
  row-gap: 4px;
  column-gap: 4px;
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  margin-left: 252px;
`;

const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${(props: GridProps) => `250px repeat(${props.numColumns}, 1fr)`};
  row-gap: 4px;
  column-gap: 4px;
  margin: -4px 0;
  height: 100%;
`;

const ColumnHeader = styled.div`
  padding: 2px;
  font-size: 12px;
`;

const TaskAreaName = styled.td`
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  padding: 1rem;
  font-family: 'Archivo';
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  width: 4rem;
`;

const TaskAreaText = styled.div`
  transform: rotate(-90deg);
  margin-left: -10em;
  margin-right: -10em;
`;

const MetricName = styled.div`
  box-shadow: ${(props: any) => `2px 2px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
`;

const MetricValue = styled.div<any>`
  background: ${(props: any) => (props.inverted ? props.theme.colors.neutral0 : props.color)};
  border-radius: 2px;
  text-transform: 'uppercase';
  color: ${(props: any) =>
    props.inverted ? props.textColor : props.darkText ? props.theme.colors.textPrimary : props.theme.colors.neutral0};
  border: ${(props: any) => props.inverted && `1px solid ${props.textColor}`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: ${(props: any) =>
    props.isFineTuned &&
    (props.inverted
      ? `radial-gradient(${props.patternColor} 1px, ${props.theme.colors.neutral0} 1px)`
      : `radial-gradient(${props.patternColor} 1px, ${props.color} 1px)`)};
  background-size: ${(props: any) => props.isFineTuned && '4px 4px'};
  padding: 0px 8px;

  &:hover {
    cursor: pointer;
  }
`;

const PercentImprovement = styled.div`
  font-weight: bold;
  margin-top: 8px;
`;

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

const SettingsButton = styled(Button)`
  min-width: 20px;
  width: 28px;
  height: 28px;
  margin-left: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0px 0px 8px;
`;

const StyledSettingsIcon = styled(SettingOutlined)`
  svg {
    width: 16px;
    height: 16px;
  }
`;

const CenterDiv = styled.div`
  text-align: center;
`;
