import {Navigate} from 'react-router-dom';
import {UserGroup, UserRole} from '../definitions/auth';
import {PageRoutes} from '../definitions/routes';
import {useDataContext} from '../context/DataContext';
import {useAuthContext} from '../context/AuthContext';
import {useVersions} from '../utils';
import {Center} from '../styles/common';
import {Spin} from 'antd';

interface Props {
  userGroup: UserGroup;
}

export const DefaultNavigation: React.FC<Props> = ({userGroup}) => {
  const [{dataVersions, currentVersion}, {setDataVersions, setCurrentVersion}] = useDataContext();
  const [{authHeaders}] = useAuthContext();

  useVersions(dataVersions, setDataVersions, currentVersion, setCurrentVersion, authHeaders);

  switch (userGroup?.role) {
    case UserRole.Performer:
      return currentVersion ? (
        <Navigate to={`/${currentVersion}/${PageRoutes.PERFORMEROVERVIEWPARTIAL}`} />
      ) : (
        <Center>
          <Spin size="large"></Spin>
        </Center>
      );
    case UserRole.Admin:
      return currentVersion ? (
        <Navigate to={`/${currentVersion}/${PageRoutes.PERFORMERCOMPARISONPARTIAL}`} />
      ) : (
        <Center>
          <Spin size="large"></Spin>
        </Center>
      );
    case UserRole.Guest:
      return <Navigate to={PageRoutes.GUEST} />;
    default:
      return <Navigate to={PageRoutes.LOGGEDOUT} />;
  }
};
