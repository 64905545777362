export enum PageRoutes {
  PERFORMEROVERVIEW = '/:version/performer-overview',
  PERFORMERCOMPARISON = '/:version/performer-comparison',
  PERFORMEROVERVIEWPARTIAL = 'performer-overview',
  PERFORMERCOMPARISONPARTIAL = 'performer-comparison',
  AUTHRESPONSE = '/oauth2/:response',
  GUEST = '/guest',
  LOGGEDOUT = '/logged-out',
  HEALTHCHECK = '/health-check',
}
