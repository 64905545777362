import './App.less';
import {ThemeProvider, css, Global} from '@emotion/react';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {theme} from './styles/emotionTheme';
import {PerformerComparison} from './views/PerformerComparison';
import {PageRoutes} from './definitions/routes';
import {PerformerOverview} from './views/PerformerOverview';
import {AuthProvider} from './context/AuthContext';
import {DataProvider} from './context/DataContext';
import {Auth} from './views/Auth';
import {Guest} from './views/Guest';
import {LoggedOut} from './views/LoggedOut';
import {HealthCheck} from './views/HealthCheck';

const router = createBrowserRouter([
  {
    path: '/',
    element: <PerformerComparison />,
  },
  {
    path: PageRoutes.PERFORMEROVERVIEW,
    element: <PerformerOverview />,
  },
  {
    path: PageRoutes.PERFORMERCOMPARISON,
    element: <PerformerComparison />,
  },
  {
    path: PageRoutes.AUTHRESPONSE,
    element: <Auth />,
  },
  {
    path: PageRoutes.GUEST,
    element: <Guest />,
  },
  {
    path: PageRoutes.LOGGEDOUT,
    element: <LoggedOut />,
  },
  {
    path: PageRoutes.HEALTHCHECK,
    element: <HealthCheck />,
  },
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Global
        styles={css`
          main {
            background: #ffffff;
          }
          * {
            font-family: 'Space Mono';
          }

          html,
          body {
            font-size: 12px !important;
          }
        `}
      />
      <AuthProvider>
        <DataProvider>
          <RouterProvider router={router} />
        </DataProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
