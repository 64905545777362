/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {useMatch} from 'react-router-dom';
import {
  Rect,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryClipContainer,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory';
import {BarMetric, FINETUNED, PerformerName} from '../../definitions/metrics';
import {PageRoutes} from '../../definitions/routes';
import {chartTheme} from '../../styles/chartTheme';
import {theme} from '../../styles/emotionTheme';
import {performerTheme} from '../../styles/performerTheme';
import {useDataContext} from '../../context/DataContext';
import {CameraOutlined} from '@ant-design/icons';
import {Button, Tooltip} from 'antd';
import {exportComponentAsPNG} from 'react-component-export-image';
import React, {useRef} from 'react';

export interface VictoryRow {
  x: string;
  y: number;
  y0: number;
  label?: string;
  fill: string;
  pattern: string | null;
}

export interface BarChartProps {
  data: BarMetric[];
  showAllModels: boolean;
  teamNames: Record<string, string>;
}

export const BarChart: React.FC<BarChartProps> = ({data, showAllModels, teamNames}) => {
  const [, {getSystemColor, getPatternColor, getReverseMetric}] = useDataContext();
  const match = useMatch({path: PageRoutes.PERFORMEROVERVIEW});
  const isOverview = match !== null;
  const baselineData = data.filter(
    (d: BarMetric) =>
      d.PerformerName === PerformerName.Baseline && (showAllModels || (!showAllModels && d.TopFlag === 'true'))
  );
  var group = baselineData[0]['Group'];
  var metric = baselineData[0]['Metric'];
  const {barData, maxValue, minValue} = getBarData(
    data,
    teamNames,
    getSystemColor,
    getPatternColor,
    getReverseMetric,
    isOverview,
    showAllModels,
    metric,
    group
  );

  const sortedBaselines = baselineData.sort((a: BarMetric, b: BarMetric) => {
    if (a.Value && b.Value && a.Value > b.Value) return 1;
    if (a.Value && b.Value && b.Value > a.Value) return -1;
    return 0;
  });
  const minBaseline = sortedBaselines[0];
  const maxBaseline = sortedBaselines[sortedBaselines.length - 1];
  const minY = minBaseline?.Value && minBaseline.Value < minValue ? minBaseline.Value : minValue;
  const maxY = maxBaseline?.Value && maxBaseline.Value > maxValue ? maxBaseline.Value : maxValue;
  const height = 80 + barData.length * 30;
  const topModelsPadding = {top: 10, left: maxY <= 0 ? 25 : 110, bottom: 35, right: maxY <= 0 ? 85 : 25};
  const allModelsPadding = {top: 10, left: 25, bottom: 35, right: 25};
  const overviewModelsPadding = {top: 5, left: maxY <= 0 ? 25 : 200, bottom: 35, right: maxY <= 0 ? 145 : 25};
  const overviewAllModelsPadding = {top: 5, left: 25, bottom: 35, right: 25};

  const downloadBarChart = useRef(null as any);

  return barData.length > 0 ? (
    <ChartCard showAllModels={showAllModels}>
      <div style={{float: 'right', padding: '0.2rem'}}>
        <Tooltip title="Download as png">
          <Button
            shape="default"
            icon={<CameraOutlined />}
            onClick={() => {
              exportComponentAsPNG(downloadBarChart, {
                fileName: 'bar_chart.png',
                html2CanvasOptions: {
                  height: downloadBarChart.current.scrollHeight,
                  width: downloadBarChart.current.scrollWidth,
                },
              });
            }}
          />
        </Tooltip>
      </div>
      <svg width="0" height="0">
        <defs>
          {barData.map(
            (datum: VictoryRow) =>
              datum.pattern && (
                <pattern
                  id={`pattern-circles-${datum.x.replaceAll(/[\W]/gi, '')}`}
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  fill="black"
                  patternUnits="userSpaceOnUse"
                  patternContentUnits="userSpaceOnUse"
                >
                  <rect id="pattern-background" width="4" height="4" fill={datum.fill} />
                  <circle id="pattern-circle" cx="1" cy="1" r="1" fill={datum.pattern} />
                </pattern>
              )
          )}
        </defs>
      </svg>
      <div style={{backgroundColor: 'white'}} ref={downloadBarChart}>
        <VictoryChart
          theme={chartTheme(400, height, 20)}
          padding={
            isOverview
              ? showAllModels
                ? overviewAllModelsPadding
                : overviewModelsPadding
              : showAllModels
              ? allModelsPadding
              : topModelsPadding
          }
          domain={{x: [0.7, barData.length + 1], y: [minY, maxY]}}
          minDomain={{y: 0, x: 0.7}}
          domainPadding={{x: 6}}
          containerComponent={<VictoryVoronoiContainer />}
        >
          {showAllModels && <VictoryAxis dependentAxis />}
          <VictoryBar
            horizontal
            barRatio={0.9}
            style={{
              data: {
                fill: ({datum}) =>
                  datum.pattern ? `url(#pattern-circles-${datum.x.replaceAll(/[\W]/gi, '')})` : datum.fill,
              },
              labels: {
                fill: theme.colors.textPrimary,
              },
            }}
            data={barData}
            labelComponent={
              <VictoryLabel
                textAnchor={maxY <= 0 ? 'end' : 'start'}
                x={minY === 0 ? 31 : maxY <= 0 ? 369 : (-minY / (maxY - minY)) * 400 + 5}
                dx={0}
                dy={-2}
                backgroundComponent={<Rect />}
                backgroundPadding={{
                  left: maxY <= 0 ? 30 : 2,
                  right: maxY <= 0 ? 2 : 30,
                  top: 2,
                  bottom: 3,
                }}
                backgroundStyle={{
                  fill: theme.colors.neutral0,
                  opacity: ({datum}) => (datum.label ? 0.8 : 0),
                }}
              />
            }
          />
          {baselineData?.map((bd: BarMetric) => (
            <VictoryLine
              key={`Baseline-${bd.RangeKey}`}
              data={[
                {x: 0.4, y: bd.Value}, // * getReverseMetric(bd.Metric, bd.Group)},
                {
                  x: isOverview ? barData.length + 0.8 : barData.length + 1,
                  y: bd.Value, //* getReverseMetric(bd.Metric, bd.Group),
                },
              ]}
              groupComponent={<VictoryClipContainer clipPadding={{top: 10, right: 10}} clipHeight={height} />}
            />
          ))}
          {baselineData?.map((bd: BarMetric) => (
            <VictoryScatter
              key={`BaselineScatter-${bd.RangeKey}`}
              style={{
                data: {fill: getSystemColor(bd.PerformerName, bd.System)},
                labels: {
                  fill: theme.colors.textPrimary,
                  fontFamily: 'Space Mono',
                  fontSize: '10px',
                },
              }}
              size={({active}) => (active ? 5 : 3)}
              labelComponent={
                <VictoryTooltip
                  cornerRadius={0}
                  pointerLength={7}
                  orientation={'top'}
                  dy={-2}
                  flyoutPadding={{top: 4, left: 22, bottom: 4, right: 22}}
                  flyoutStyle={{
                    stroke: theme.colors.neutral0Shadow,
                    fill: theme.colors.neutral0,
                  }}
                />
              }
              data={[
                {
                  x: isOverview ? barData.length + 0.8 : barData.length + 1,
                  y: bd.Value, //* getReverseMetric(bd.Metric, bd.Group),
                  label: isOverview
                    ? `${bd.PerformerName} ${bd.Genre} ${bd.System}`
                    : `${bd.PerformerName} ${bd.System}`,
                  symbol: 'triangleDown',
                },
              ]}
            />
          ))}
        </VictoryChart>
      </div>
    </ChartCard>
  ) : (
    <div />
  );
};

export const getBarData = (
  data: BarMetric[],
  teamNames: Record<string, string>,
  getSystemColor: (performerName: string, systemName: string) => string,
  getPatternColor: (performerName: string, systemName: string) => string,
  getReverseMetric: (metric: string, group: string) => number,
  isOverview: boolean,
  showAll?: boolean,
  metric?: any,
  group?: any
) => {
  const barData: VictoryRow[] = [];
  let maxValue = 0;
  let minValue = 0;
  for (let d of data) {
    if (
      d.Value !== null &&
      d.PerformerName !== PerformerName.Baseline &&
      (showAll || (!showAll && d.TopFlag === 'true'))
    ) {
      const teamName = teamNames[d.PerformerName];
      const theme = performerTheme[d.PerformerName as PerformerName];
      let fillColor = theme?.primary;
      let patternColor = theme?.text;
      if (showAll || isOverview) {
        fillColor = getSystemColor(d.PerformerName, d.System);
        patternColor = getPatternColor(d.PerformerName, d.System);
      }
      const row = {
        x: isOverview ? (showAll ? `${d.Genre} ${d.System}` : d.Genre) : showAll ? `${teamName} ${d.System}` : teamName,
        y: d.Value, //* getReverseMetric(d.Metric, d.Group),
        y0: 0,
        label: isOverview ? (showAll ? `${d.Genre} ${d.System}` : '') : showAll ? `${teamName} ${d.System}` : '',
        fill: fillColor,
        pattern: d.System.includes(FINETUNED) ? patternColor : null,
      };
      barData.push(row);
      if (row.y > maxValue) {
        maxValue = row.y;
      }
      if (row.y < minValue) {
        minValue = row.y;
      }
    }
  }
  const sortedData = barData.sort((a: VictoryRow, b: VictoryRow) => {
    var revMetric = metric === undefined && group === undefined ? 1 : getReverseMetric(metric, group);
    if (a.y > b.y) {
      if (revMetric === -1) {
        return -1;
      }
      return 1;
    }

    if (b.y > a.y) {
      if (revMetric === -1) {
        return 1;
      }
      return -1;
    }

    return 0;
  });
  return {barData: sortedData, maxValue, minValue};
};

const ChartCard = styled.div<any>`
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  width: 100%;
  display: ${(props: any) => props.showAllModels && 'flex'};
`;
