/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {Switch} from 'antd';
import {useCallback, useMemo, useState} from 'react';
import {AllBarMetrics, MetricsSummary, TaskArea, TaskAreaNames} from '../../definitions/metrics';
import {BarChart} from './BarChart';
import {LineChart} from './LineChart';
import {DropdownMenu, DropdownMenuItem, getDropdownItemsFromArray} from '../DropdownMenu';
import {useDataContext} from '../../context/DataContext';
import {useMatch} from 'react-router-dom';
import {ErrorBoundary} from '../ErrorBoundary';
import {PageRoutes} from '../../definitions/routes';

export interface MetricsBarProps {
  data: AllBarMetrics;
}

export const MetricsBar: React.FC<MetricsBarProps> = ({data}) => {
  const [{metricsSummary, primaryMetrics, performerTeams, performerSummary}] = useDataContext();
  const taskAreasList = metricsSummary?.map(
    (metricsGroup: MetricsSummary) => TaskAreaNames[metricsGroup.Group as TaskArea]
  );
  const taskAreaItems = taskAreasList && getDropdownItemsFromArray(taskAreasList, 'taskArea');
  const getSortFunc = useCallback(
    (groupName: string) => {
      return (a: string, b: string) => {
        if (primaryMetrics.includes(`${groupName}|${a}`)) return -1;
        else if (primaryMetrics.includes(`${groupName}|${b}`)) return 1;
        return 0;
      };
    },
    [primaryMetrics]
  );
  const TA1Items = useMemo(() => {
    const metricsGroup =
      metricsSummary?.find((metricsGroup) => TaskAreaNames[metricsGroup.Group as TaskArea] === TaskAreaNames.TA1) ||
      metricsSummary?.[0];
    metricsGroup?.MetricsList.sort(getSortFunc(metricsGroup.Group));
    return metricsGroup?.MetricsList ? getDropdownItemsFromArray(metricsGroup.MetricsList, 'TA1Metric') : [];
  }, [metricsSummary, getSortFunc]);
  const TA2Items = useMemo(() => {
    const metricsGroup =
      metricsSummary?.find((metricsGroup) => TaskAreaNames[metricsGroup.Group as TaskArea] === TaskAreaNames.TA2) ||
      metricsSummary?.[1];
    if (!metricsGroup) return [];
    metricsGroup.MetricsList.sort(getSortFunc(metricsGroup.Group));
    return getDropdownItemsFromArray(metricsGroup.MetricsList, 'TA2Metric');
  }, [metricsSummary, getSortFunc]);
  const TA3Items = useMemo(() => {
    const metricsGroup =
      metricsSummary?.find((metricsGroup) => TaskAreaNames[metricsGroup.Group as TaskArea] === TaskAreaNames.TA3) ||
      metricsSummary?.[2];
    if (!metricsGroup) return [];
    metricsGroup.MetricsList.sort(getSortFunc(metricsGroup.Group));
    return getDropdownItemsFromArray(metricsGroup.MetricsList, 'TA3Metric');
  }, [metricsSummary, getSortFunc]);
  const [taskArea, setTaskArea] = useState<DropdownMenuItem | undefined>(taskAreaItems?.[0]);
  const [metrics, setMetrics] = useState<Record<TaskAreaNames, DropdownMenuItem>>({
    [TaskAreaNames.TA1]: TA1Items[0],
    [TaskAreaNames.TA2]: TA2Items[0],
    [TaskAreaNames.TA3]: TA3Items[0],
  });
  const [showAllModels, setShowAllModels] = useState<boolean>(false);
  var [showOverlayDet, setshowOverlayDet] = useState<boolean>(true);

  const onToggleAllModels = (checked: boolean) => {
    setShowAllModels(checked);
  };

  const onToggleOverlayDet = (checked: boolean) => {
    setshowOverlayDet(checked);
  };

  const setMetric = (item: DropdownMenuItem, key: string) => {
    setMetrics((prevMetrics) => {
      return {
        ...prevMetrics,
        [key]: item,
      };
    });
  };

  const barData = useMemo(() => {
    const TA = Object.keys(TaskAreaNames).find((key: string) => TaskAreaNames[key as TaskArea] === taskArea?.label);
    return data[`${TA}|${metrics[taskArea?.label as TaskAreaNames].label}`];
  }, [taskArea, metrics, data]);

  const match = useMatch({path: PageRoutes.PERFORMEROVERVIEW});
  const isOverview = match !== null;

  const overlayDisabled = useMemo(() => {
    var genreList: string[] =
      barData !== undefined ? Array.from(new Set(barData.map((d) => d.RangeKey.split('|')[0]))) : [];
    if (isOverview && genreList.length === 1) {
      setshowOverlayDet(true);
      return true;
    } else if (!isOverview && performerSummary?.length === 1) {
      setshowOverlayDet(true);
      return true;
    }
    return false;
  }, [isOverview, barData, performerSummary]);

  return (
    <MetricsBarContainer>
      <div>
        <Settings>
          <DropdownMenu
            items={taskAreaItems || []}
            setSelectedItemInParent={setTaskArea}
            css={metricsDropdownStyles(true)}
          />
          <SwitchContainer>
            <AllModelsContainer>
              <Switch size={'small'} checked={showAllModels} onChange={onToggleAllModels} />
              <AllModelsText>All models</AllModelsText>
            </AllModelsContainer>
            <AllModelsContainer>
              <Switch
                size={'small'}
                checked={showOverlayDet}
                disabled={overlayDisabled}
                onChange={onToggleOverlayDet}
              />
              <AllModelsText>Overlay</AllModelsText>
            </AllModelsContainer>
          </SwitchContainer>
        </Settings>
        {TA1Items.length > 0 && (
          <DropdownMenu
            items={TA1Items}
            setSelectedItemInParent={(item) => setMetric(item, TaskAreaNames.TA1)}
            css={metricsDropdownStyles(taskArea?.label === TaskAreaNames.TA1)}
          />
        )}
        {TA2Items.length > 0 && (
          <DropdownMenu
            items={TA2Items}
            setSelectedItemInParent={(item) => setMetric(item, TaskAreaNames.TA2)}
            css={metricsDropdownStyles(taskArea?.label === TaskAreaNames.TA2)}
          />
        )}
        {TA3Items.length > 0 && (
          <DropdownMenu
            items={TA3Items}
            setSelectedItemInParent={(item) => setMetric(item, TaskAreaNames.TA3)}
            css={metricsDropdownStyles(taskArea?.label === TaskAreaNames.TA3)}
          />
        )}
      </div>
      <ErrorBoundary maxHeight={150}>
        <LineChart
          teamNames={performerTeams}
          showAllModels={showAllModels}
          overlayDet={showOverlayDet}
          taskArea={taskArea?.label || ''}
          metricVal={metrics}
        />
      </ErrorBoundary>
      <ErrorBoundary maxHeight={150}>
        {barData && <BarChart data={barData} showAllModels={showAllModels} teamNames={performerTeams} />}
      </ErrorBoundary>
    </MetricsBarContainer>
  );
};

const MetricsBarContainer = styled.div`
  width: 35vw;
  min-width: 375px;
  max-width: 500px;
  padding: 1.5rem;
`;

const Settings = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const metricsDropdownStyles = (show: boolean) => css`
  display: ${show ? 'block' : 'none'};
  margin-bottom: 12px;
`;

const AllModelsText = styled.span`
  margin-left: 0.5rem;
  font-family: 'Archivo';
  font-size: 14px;
`;

const AllModelsContainer = styled.div`
  min-width: 100px;
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: right;
`;
