import {Spin} from 'antd';
import React from 'react';
import {useLocation} from 'react-router-dom';
import {useAuthContext} from '../context/AuthContext';
import {DefaultNavigation} from '../components/DefaultNavigation';
import {Center} from '../styles/common';

export const Auth: React.FC<{}> = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const [{user, loggingIn, userGroup}, {logInUser}] = useAuthContext();

  if (code) {
    logInUser(code);
  }

  return loggingIn || !user ? (
    <Center>
      <Spin size="large" />
    </Center>
  ) : (
    <DefaultNavigation userGroup={userGroup} />
  );
};
