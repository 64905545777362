import styled from '@emotion/styled';
import {FINETUNED, PerformerName, PerformerSummary, TeamName} from '../../definitions/metrics';
import React from 'react';
import {ColorCircle, HeaderText, LegendCard, Row, Triangle} from './Shared';
import {useDataContext} from '../../context/DataContext';
import {theme} from '../../styles/emotionTheme';

export interface SystemLegendProps {
  teamName: TeamName | null;
  performerSummary: PerformerSummary[];
}

export interface SystemLegendItemProps {
  performerName?: string;
  systemName: string;
}

export const SystemLegend: React.FC<SystemLegendProps> = ({teamName, performerSummary}) => {
  const systemData = performerSummary && performerSummary.find((d) => d.Team === teamName);
  const systemList = systemData?.SystemList;
  const baselineSystemData = performerSummary && performerSummary.find((d) => d.Team === TeamName.Baseline);
  const baselineSystemList = baselineSystemData?.SystemList;

  return (
    <LegendCard>
      <Row>
        <HeaderText>Legend</HeaderText>
      </Row>
      <Grid>
        {baselineSystemList?.map((systemName: string) => (
          <React.Fragment key={systemName}>
            <SystemLegendItem performerName={baselineSystemData?.PerformerName} systemName={systemName} />
          </React.Fragment>
        ))}
        {systemList?.map((systemName: string) => (
          <React.Fragment key={systemName}>
            <SystemLegendItem performerName={systemData?.PerformerName} systemName={systemName} />
          </React.Fragment>
        ))}
      </Grid>
    </LegendCard>
  );
};

export const SystemLegendItem: React.FC<SystemLegendItemProps> = ({performerName, systemName}) => {
  const [, {getSystemColor, getPatternColor}] = useDataContext();
  const color = performerName ? getSystemColor(performerName, systemName) : theme.colors.textPrimary;
  const patternColor = performerName ? getPatternColor(performerName, systemName) : theme.colors.neutral40;
  return (
    <Row>
      {performerName === PerformerName.Baseline ? (
        <Triangle color={color} size={'18px'} />
      ) : (
        <ColorCircle
          color={color}
          patternColor={patternColor}
          isFineTuned={systemName.includes(FINETUNED)}
          size={'18px'}
        />
      )}
      <PerformerLabel color={color}>{systemName}</PerformerLabel>
    </Row>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(3, 20px);
  grid-auto-flow: column;
  margin-top: 1.5rem;
  row-gap: 0.5rem;
  column-gap: 1rem;
`;

const PerformerLabel = styled.div`
  border: 2px solid ${(props: any) => props.color};
  border-radius: 1rem;
  padding: 0px 6px 0px 6px;
  margin-left: 0.5rem;
  font-size: 10px;
`;
