/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {Page} from '../styles/common';

export const HealthCheck: React.FC<{}> = () => {
  return (
    <Page>
      <Para>Hello! The App is Healthy</Para>
    </Page>
  );
};

export const Para = styled.p`
  padding: 1.75rem;
`;
