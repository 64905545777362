import React, {useCallback, useMemo, useState} from 'react';
import {
  MetricsSummary,
  PerformerSummary,
  ComparisonValues,
  PerformerValues,
  TeamName,
  PerformerName,
  FINETUNED,
  DataVersions,
} from '../definitions/metrics';
import {performerTheme} from '../styles/performerTheme';

interface IDataVars {
  metricsSummary: MetricsSummary[] | null;
  performerSummary: PerformerSummary[] | null;
  performerValues: PerformerValues | null;
  fetchedPerformerTeam: TeamName | null;
  performerComparisonValues: ComparisonValues | null;
  performerFriendlyNames: Record<string, string>;
  performerTeams: Record<string, string>;
  teamSystems: Record<string, string[]>;
  teamsList: TeamName[];
  defaultDisplayMetrics: string[];
  primaryMetrics: string[];
  selectedGenre: string | null;
  dataVersions: DataVersions | null;
  currentVersion: string;
}

interface IDataFuncs {
  setMetricsSummary: React.Dispatch<React.SetStateAction<MetricsSummary[] | null>>;
  setPerformerSummary: React.Dispatch<React.SetStateAction<PerformerSummary[] | null>>;
  setPerformerValues: React.Dispatch<React.SetStateAction<PerformerValues | null>>;
  setDataVersions: React.Dispatch<React.SetStateAction<DataVersions | null>>;
  setCurrentVersion: React.Dispatch<React.SetStateAction<string>>;
  setFetchedPerformerTeam: React.Dispatch<React.SetStateAction<TeamName | null>>;
  setPerformerComparisonValues: React.Dispatch<React.SetStateAction<ComparisonValues | null>>;
  setSelectedGenre: React.Dispatch<React.SetStateAction<string | null>>;
  getSystemColor: (performerName: string, systemName: string) => string;
  getPatternColor: (performerName: string, systemName: string, inverted?: boolean) => string;
  getReverseMetric: (metric: string, group: string) => number;
  invalidateData: () => void;
}

const DataContext = React.createContext<[IDataVars, IDataFuncs] | undefined>(undefined);

export const DataProvider = (props: any) => {
  const [metricsSummary, setMetricsSummary] = useState<MetricsSummary[] | null>();
  const [performerSummary, setPerformerSummary] = useState<PerformerSummary[] | null>();
  const [dataVersions, setDataVersions] = useState<DataVersions | null>();
  const [currentVersion, setCurrentVersion] = useState<string>();
  const [performerValues, setPerformerValues] = useState<PerformerValues | null>();
  const [fetchedPerformerTeam, setFetchedPerformerTeam] = useState<TeamName | null>(null);
  const [performerComparisonValues, setPerformerComparisonValues] = useState<ComparisonValues | null>();
  const [selectedGenre, setSelectedGenre] = useState<string | null>(null);

  const performerFriendlyNames = useMemo(() => {
    let friendlyNames: Record<string, string> = {};
    performerSummary?.forEach((data: PerformerSummary) => {
      friendlyNames[data.PerformerName] = data.PerformerShortNames;
    });
    return friendlyNames;
  }, [performerSummary]);

  const performerTeams = useMemo(() => {
    let teams: Record<string, string> = {};
    performerSummary?.forEach((data: PerformerSummary) => {
      teams[data.PerformerName] = data.Team;
    });
    return teams;
  }, [performerSummary]);

  const teamSystems = useMemo(() => {
    let systems: Record<string, string[]> = {};
    performerSummary?.forEach((data: PerformerSummary) => {
      systems[data.Team] = data.SystemList.filter((system) => !system.includes(FINETUNED));
    });
    return systems;
  }, [performerSummary]);

  const teamsList = useMemo(() => {
    const teams = performerSummary && performerSummary.map((summary) => summary.Team);
    const baselineIndex = teams?.indexOf(TeamName.Baseline);
    if (teams && baselineIndex !== undefined && baselineIndex !== -1) {
      teams.splice(baselineIndex, 1);
    }
    return teams;
  }, [performerSummary]);

  const defaultDisplayMetrics = useMemo(() => {
    let metrics: string[] = [];
    metricsSummary?.forEach((data: MetricsSummary) => {
      let newMetrics = data.DefaultDisplayMetrics.map((metric) => `${data.Group}|${metric}`);
      metrics = metrics.concat(newMetrics);
    });
    return metrics;
  }, [metricsSummary]);

  const primaryMetrics = useMemo(() => {
    let metrics: string[] = [];
    metricsSummary?.forEach((data: MetricsSummary) => {
      let newMetric = `${data.Group}|${data.PrimaryMetrics}`;
      metrics.push(newMetric);
    });
    return metrics;
  }, [metricsSummary]);

  const invalidateData = () => {
    setMetricsSummary(null);
    setPerformerSummary(null);
    setPerformerValues(null);
    setFetchedPerformerTeam(null);
    setPerformerComparisonValues(null);
  };

  const getSystemColor = useCallback(
    (performer: string, system: string) => {
      const theme = performerTheme[performer as PerformerName];
      const team = performerTeams[performer];
      const systemList = teamSystems[team];
      if (theme) {
        const systemName = system.replace(FINETUNED, '');
        const systemNum = systemList.indexOf(systemName);
        return systemNum < theme.shades.length ? theme.shades[systemNum] : theme.primary;
      }
    },
    [performerTeams, teamSystems]
  );

  const getPatternColor = useCallback(
    (performer: string, system: string, inverted?: boolean) => {
      const theme = performerTheme[performer as PerformerName];
      const team = performerTeams[performer];
      const systemList = teamSystems[team];
      if (theme) {
        const systemName = system.replace(FINETUNED, '');
        const systemNum = systemList.indexOf(systemName);
        return systemNum < theme.patterns.length
          ? inverted
            ? theme.shades[2]
            : theme.patterns[systemNum]
          : theme.text;
      }
    },
    [performerTeams, teamSystems]
  );

  const getReverseMetric = useCallback(
    (metric: string, group: string) => {
      if (metricsSummary && metricsSummary.length >= 1) {
        const summary = metricsSummary.find((summary) => summary.Group === group);
        const reverseMetrics = summary?.ReverseMetrics || {};
        if (metric in reverseMetrics) {
          return reverseMetrics[metric];
        }
        return 1;
      }
    },
    [metricsSummary]
  );

  const value = [
    {
      metricsSummary,
      performerSummary,
      dataVersions,
      currentVersion,
      performerValues,
      fetchedPerformerTeam,
      performerComparisonValues,
      performerFriendlyNames,
      performerTeams,
      teamSystems,
      teamsList,
      defaultDisplayMetrics,
      primaryMetrics,
      selectedGenre,
    },
    {
      setMetricsSummary,
      setPerformerSummary,
      setDataVersions,
      setCurrentVersion,
      setPerformerValues,
      setFetchedPerformerTeam,
      setPerformerComparisonValues,
      setSelectedGenre,
      getSystemColor,
      getPatternColor,
      getReverseMetric,
      invalidateData,
    },
  ];

  return <DataContext.Provider value={value} {...props} />;
};

export const useDataContext = () => {
  const context = React.useContext(DataContext);
  if (!context) {
    throw new Error('useDataContext must be used within a DataProvider');
  }
  return context;
};
