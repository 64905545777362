import React, {useCallback, useMemo} from 'react';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import jwt_decode from 'jwt-decode';
import sha256 from 'crypto-js/sha256';
import WordArray from 'crypto-js/lib-typedarrays';
import Base64url from 'crypto-js/enc-base64url';
import {useState} from 'react';
import {UserGroup, UserRole} from '../definitions/auth';
import {TeamName} from '../definitions/metrics';

interface IAuthVars {
  userPool: AmazonCognitoIdentity.CognitoUserPool;
  user: AmazonCognitoIdentity.CognitoUser | null;
  loggingIn: boolean;
  userGroup: UserGroup;
  selectedTeam: TeamName | null;
  loginURI: string;
  accessToken: string;
  authHeaders: {headers: {}};
}

interface IAuthFuncs {
  logInUser: (code: string) => void;
  setSelectedTeam: React.Dispatch<React.SetStateAction<TeamName | null>>;
}

const AuthContext = React.createContext<[IAuthVars, IAuthFuncs] | undefined>(undefined);

export const AuthProvider = (props: any) => {
  const storage = useMemo(() => {
    return new AmazonCognitoIdentity.CookieStorage({
      domain: window.config.domain || '',
      secure: window.config.domainSecure,
      expires: 1,
      sameSite: 'strict',
    });
  }, []);
  const userPool = useMemo(() => {
    return new AmazonCognitoIdentity.CognitoUserPool({
      UserPoolId: window.config.userPoolId || '',
      ClientId: window.config.clientId || '',
      Storage: storage,
    });
  }, [storage]);
  const verifier = useMemo(() => {
    const verifier = storage.getItem('codeVerifier');
    if (!verifier) {
      const newVerifier = WordArray.random(32).toString(Base64url);
      storage.setItem('codeVerifier', newVerifier);
      return newVerifier;
    } else {
      return verifier;
    }
  }, [storage]);
  const [user, setUser] = useState<AmazonCognitoIdentity.CognitoUser | null>(userPool.getCurrentUser());
  const [loggingIn, setLoggingIn] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<TeamName | null>(null);
  const [accessToken, setAccessToken] = useState<string>();

  const generateCodeChallenge = useCallback(() => {
    return sha256(verifier).toString(Base64url);
  }, [verifier]);
  const loginURI = `${window.config.cognitoDomain}/login?client_id=${
    window.config.clientId
  }&response_type=code&scope=email+openid&code_challenge_method=S256&code_challenge=${generateCodeChallenge()}&redirect_uri=${encodeURIComponent(
    window.config.cognitoRedirectUri || ''
  )}`;

  const logInUser = useCallback(
    (code: string) => {
      if (loggingIn || user) return;

      setLoggingIn(true);
      fetch(`${window.config.cognitoDomain}/oauth2/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          code: code || '',
          redirect_uri: window.config.cognitoRedirectUri || '',
          client_id: window.config.clientId || '',
          code_verifier: verifier,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.access_token) {
            const decoded: any = jwt_decode(response.access_token);
            /** Create Cognito User */
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser({
              Username: decoded.username,
              Pool: userPool,
              Storage: storage,
            });

            /** Set Cognito User Sign In Session */
            const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({AccessToken: response.access_token});
            const IdToken = new AmazonCognitoIdentity.CognitoIdToken({IdToken: response.id_token});
            const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({RefreshToken: response.refresh_token});
            const session = new AmazonCognitoIdentity.CognitoUserSession({
              IdToken,
              AccessToken,
              RefreshToken,
            });

            cognitoUser.setSignInUserSession(session);

            setUser(cognitoUser);
            setLoggingIn(false);
          }
        });
    },
    [user, loggingIn, storage, userPool, verifier]
  );

  const parseGroup = (session: AmazonCognitoIdentity.CognitoUserSession) => {
    setAccessToken(session.getAccessToken().getJwtToken());
    const decoded: any = jwt_decode(session.getIdToken().getJwtToken());
    const group = decoded['cognito:groups']?.[0];
    if (!group) {
      return {
        group: '',
        role: UserRole.Guest,
      };
    }

    let role: UserRole;
    if (group.startsWith('performer')) {
      role = UserRole.Performer;
      let teamName: keyof typeof TeamName = group.replace('performer-', '');
      setSelectedTeam(TeamName[teamName]);
    } else if (group.startsWith('admin')) {
      role = UserRole.Admin;
    } else {
      role = UserRole.Guest;
    }

    return {
      group,
      role,
    };
  };

  const userGroup = useMemo(() => {
    const defaultGroup = {group: '', role: null};
    if (!user) return defaultGroup;

    return user.getSession((err: any, session: AmazonCognitoIdentity.CognitoUserSession) => {
      if (err) {
        return defaultGroup;
      } else {
        /*
         * I suspect this code does not work because user.refreshSession() is not passing
         * a code verifier or working as intended. TODO: debug this and possibly write
         * custom refresh session function like the logInUser function.
         */
        if (!session.isValid()) {
          user.refreshSession(
            session.getRefreshToken(),
            (err: any, newSession: AmazonCognitoIdentity.CognitoUserSession) => {
              if (err) {
                return defaultGroup;
              } else {
                return parseGroup(newSession);
              }
            }
          );
        } else {
          return parseGroup(session);
        }
      }
    });
  }, [user]);

  const authHeaders = useMemo(() => {
    return accessToken
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      : null;
  }, [accessToken]);

  const value = [
    {
      userPool,
      user,
      loggingIn,
      userGroup,
      selectedTeam,
      loginURI,
      accessToken,
      authHeaders,
    },
    {
      logInUser,
      setSelectedTeam,
    },
  ];

  return <AuthContext.Provider value={value} {...props} />;
};

export const useAuthContext = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return context;
};
