import React from 'react';
import styled from '@emotion/styled';

interface ErrorBoundaryProps {
  children: any;
  invisible?: boolean;
  maxWidth?: number;
  maxHeight?: number;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, {hasError: boolean}> {
  constructor(props: any) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return this.props.invisible ? (
        <div />
      ) : (
        <Container maxWidth={this.props.maxWidth} maxHeight={this.props.maxHeight}>
          <p>Something went wrong</p>
        </Container>
      );
    }

    return this.props.children;
  }
}

const Container = styled.div<any>`
  width: 100%;
  height: 100%;
  max-width: ${(props) => props.maxWidth && `${props.maxWidth}px`};
  max-height: ${(props) => props.maxHeight && `${props.maxHeight}px`};
  display: flex;
  justify-content: center;
  align-items: center;
`;
