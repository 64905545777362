export enum UserRole {
  Guest = 'Guest',
  Performer = 'Performer',
  Admin = 'Admin',
}

export interface UserGroup {
  group: string;
  role: UserRole | null;
}
