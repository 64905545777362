/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {Button} from 'antd';
import {Header} from '../components/Header';
import {Content, Page} from '../styles/common';
import {useAuthContext} from '../context/AuthContext';
import Game from '../assets/Game.png';
import {HeaderText} from '../components/Legend/Shared';

export const LoggedOut: React.FC<{}> = () => {
  const [{loginURI}] = useAuthContext();
  return (
    <Page>
      <Header />
      <Content>
        <InnerContent>
          <CommonCardStyle>
            <img src={Game} alt={'Arcade game high score'} height="150px" width="99px" style={{marginRight: '20px'}} />
            <TextContainer>
              <HeaderText>Welcome!</HeaderText>
              <Description>To access the leaderboard and evaluations, please sign in</Description>
              <ButtonContainer>
                <Button href={loginURI} type={'primary'} block>
                  Sign in
                </Button>
              </ButtonContainer>
            </TextContainer>
          </CommonCardStyle>
        </InnerContent>
      </Content>
    </Page>
  );
};

const CommonCardStyle = styled.div<any>`
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3rem;
  margin-top: 3rem;
`;

const TextContainer = styled.div`
  text-align: center;
`;

const Description = styled.p`
  width: 250px;
  margin: 0.5rem 0 3rem 0;
`;

const ButtonContainer = styled.div`
  padding: 0 3rem;
`;

const InnerContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;
