import styled from '@emotion/styled';

export const LegendCard = styled.div`
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
  padding: 1rem;
  margin-right: 1.5rem;
  width: 60%;
  min-width: 630px;
`;

export const HeaderText = styled.div`
  font-family: 'Press Start 2P';
  font-size: 14px;
`;

export const SubHeader = styled.div`
  margin-left: 0.5rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ColorCircle = styled.div<any>`
  width: ${(props: any) => props.size};
  height: ${(props: any) => props.size};
  border-radius: ${(props: any) => !props.isFineTuned && `calc(${props.size} / 2)`};
  background: ${(props: any) => props.color};
  flex-shrink: 0;
  background-image: ${(props: any) =>
    props.isFineTuned && `radial-gradient(${props.patternColor} 1px, ${props.color} 1px)`};
  background-size: ${(props: any) => props.isFineTuned && '4px 4px'};
  margin-right: ${(props: any) => props.spaceOut && '3px'};
`;

export const DashedColorCircle = styled.div<any>`
  width: ${(props: any) => props.size};
  height: ${(props: any) => props.size};
  border-radius: ${(props: any) => `calc(${props.size} / 2)`};
  border: ${(props: any) => `1px dashed ${props.color}`};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const Triangle = styled.div<any>`
  width: 0;
  height: 0;
  border-left: ${(props: any) => `calc(${props.size} / 2) solid transparent`};
  border-right: ${(props: any) => `calc(${props.size} / 2) solid transparent`};
  border-top: ${(props: any) => `${props.size} solid ${props.color}`};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;
