import {useEffect} from 'react';
import {DataVersions, MetricsSummary, PerformerSummary} from './definitions/metrics';
import {Params} from 'react-router-dom';

/**
 * Custom hooks for fetching metricsSummary, performer Summary, and versions data.
 * These are used in both the PerformerComparion and PerformerOverview pages.
 */

export function useMetricsSummary(
  metricsSummary: MetricsSummary[] | null,
  setMetricsSummary: React.Dispatch<React.SetStateAction<MetricsSummary[] | null>>,
  setFetchDataErrors: React.Dispatch<React.SetStateAction<Record<string, any>>>,
  authHeaders: Record<string, any>,
  currentVersion: string
) {
  useEffect(() => {
    if (!metricsSummary && currentVersion) {
      fetch(`${window.config.dashboardApiEndpoint}/${currentVersion}/metrics_summary`, authHeaders)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(res.statusText);
        })
        .then((metricsData) => {
          setMetricsSummary(metricsData);
        })
        .catch((error: any) => {
          setFetchDataErrors((prev) => ({
            ...prev,
            metricsSummary: error,
          }));
        });
    }
  }, [metricsSummary, setMetricsSummary, authHeaders, setFetchDataErrors, currentVersion]);
}

export function usePerformerSummary(
  performerSummary: PerformerSummary[] | null,
  setPerformerSummary: React.Dispatch<React.SetStateAction<PerformerSummary[] | null>>,
  setFetchDataErrors: React.Dispatch<React.SetStateAction<Record<string, any>>>,
  authHeaders: Record<string, any>,
  currentVersion: string
) {
  useEffect(() => {
    if (!performerSummary && currentVersion) {
      fetch(`${window.config.dashboardApiEndpoint}/${currentVersion}/performer_summary`, authHeaders)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(res.statusText);
        })
        .then((performerData) => {
          setPerformerSummary(performerData);
        })
        .catch((error: any) => {
          setFetchDataErrors((prev) => ({
            ...prev,
            performerSummary: error,
          }));
        });
    }
  }, [performerSummary, setPerformerSummary, authHeaders, setFetchDataErrors, currentVersion]);
}

export function useVersions(
  dataVersions: DataVersions | null,
  setDataVersions: React.Dispatch<React.SetStateAction<DataVersions | null>>,
  currentVersion: string,
  setCurrentVersion: React.Dispatch<React.SetStateAction<string>>,
  authHeaders: Record<string, any>,
  params?: Readonly<Params<string>>
) {
  useEffect(() => {
    if (!dataVersions && authHeaders) {
      fetch(`${window.config.dashboardApiEndpoint}/versions`, authHeaders)
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw new Error(res.statusText);
        })
        .then((versions: DataVersions) => {
          setDataVersions(versions);
          if (!currentVersion && !params?.version) {
            setCurrentVersion(versions.DefaultVersion);
          }
        })
        .catch((error: any) => {
          // hardcoding backup default if version fetch fails
          setCurrentVersion('1.1');
        });
    }
  }, [dataVersions, setDataVersions, authHeaders]);
}
