/** @jsxImportSource @emotion/react */
import React, {useMemo, useState} from 'react';
import {Dropdown, Button, MenuProps} from 'antd';
import {CaretDownFilled} from '@ant-design/icons';
import styled from '@emotion/styled';

export interface DropdownMenuItem {
  label: string;
  key: string;
  icon?: React.ReactNode;
}

export interface DropdownMenuProps extends React.HTMLProps<HTMLDivElement> {
  items: DropdownMenuItem[];
  dropdownLabel?: string;
  selectedItem?: DropdownMenuItem;
  setSelectedItemInParent?: (item: DropdownMenuItem) => void;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  items,
  dropdownLabel,
  selectedItem,
  setSelectedItemInParent,
  ...props
}) => {
  const [selectedKey, setSelectedKey] = useState<string>(items[0].key);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setSelectedKey(e.key);
    if (setSelectedItemInParent) {
      const newItem = items.find((item) => item?.key === e.key);
      newItem && setSelectedItemInParent(newItem);
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
    selectedKeys: selectedItem ? [selectedItem.key] : [selectedKey],
  };

  const selected = useMemo(() => {
    return selectedItem ? selectedItem : items.find((item) => item?.key === selectedKey);
  }, [items, selectedKey, selectedItem]);

  return (
    <Dropdown menu={menuProps} {...props}>
      <DropdownButton>
        <span>{selected?.label || dropdownLabel || 'Dropdown'}</span>
        <CaretDownFilled />
      </DropdownButton>
    </Dropdown>
  );
};

export const getDropdownItemsFromArray = (arrayItems: string[], keyBase: string) => {
  return arrayItems.map((arrayItem: string, index: number) => {
    return {
      label: arrayItem,
      key: `${keyBase}-${index}`,
    };
  });
};

const DropdownButton = styled(Button)`
  box-shadow: ${(props: any) => `3px 3px 1px ${props.theme.colors.neutral0Shadow}`};
  border: ${(props: any) => `1px solid ${props.theme.colors.neutral0Shadow}`};
  border-radius: 2px;
`;
